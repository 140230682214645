import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Button from '../Button';
import { Column, Container, Row } from '../Grid';
import Icon from '../Icon';
import Image from '../Image';
import OutboundLink from '../OutboundLink';
import Share from '../Share';
import Subscribe from '../Subscribe';
import './styles.scss';

/**
 * A global footer component that is populated with properties from `gatsby-config.js`
 */

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          partnerUrl
          programName
          aboutProgram
          menuLinks {
            label
            path
          }
        }
      }
    }
  `);

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer">
      <div className="footer__top">
        <Container>
          <div className="footer__to_top">
            <button className="footer__to_top-button" onClick={handleBackToTop}>
              Back to Top
              <span className="footer__to_top-icon">
                <Icon name="arrowup" />
              </span>
            </button>
          </div>
          <div className="footer__content">
            <Row>
              <Column size={5}>
                <div className="footer__logos">
                  <OutboundLink
                    to="https://www.discoveryeducation.com"
                    target="_blank"
                    className="footer__de-logo"
                  >
                    <Image filename="de-logo.png" alt="Discovery Education" />
                  </OutboundLink>
                  <div className="footer__logo-divider" />
                  <OutboundLink
                    to={site.siteMetadata.partnerUrl}
                    target="_blank"
                    className="footer__partner-logo"
                  >
                    <Image
                      filename={site.siteMetadata.partnerLogo}
                      alt={site.siteMetadata.partnerName}
                      style={{ maxHeight: '70px' }}
                    />
                  </OutboundLink>
                </div>
                <div className="footer__about">
                  <p>{site.siteMetadata.aboutProgram}</p>
                  <Button to="https://www.chsfoundation.org/" target="_blank">
                    Learn more
                  </Button>
                </div>
              </Column>
              <Column size={4} offset={2}>
                <h2 className="footer__heading">Stay Connected</h2>
                <p>
                  Stay up to date on latest curriculum additions, virtual field
                  trips and educational materials.
                </p>
                <Subscribe
                  trigger={
                    <Button className="footer__signup-button">
                      Sign up for updates
                    </Button>
                  }
                />
                <Share />
              </Column>
            </Row>
          </div>
        </Container>
      </div>

      <div className="footer__base">
        <Container className="footer__base-container" fullWidth>
          <div className="footer__copyright">
            © {new Date().getFullYear()} Discovery Education, Inc.
          </div>
          <div className="footer__base-links">
            <ul className="footer__base-list">
              <li className="footer__base-list-item">
                <OutboundLink
                  to="/"
                  className="footer__base-link cookie_settings"
                >
                  Cookie Settings
                </OutboundLink>
              </li>
              <li className="footer__base-list-item">
                <OutboundLink
                  to="https://www.discoveryeducation.com/privacy-policy/"
                  className="footer__base-link"
                >
                  Privacy Policy
                </OutboundLink>
              </li>
              <li className="footer__base-list-item">
                <OutboundLink
                  to="https://www.discoveryeducation.com/terms-of-use/"
                  className="footer__base-link"
                >
                  Terms of Use
                </OutboundLink>
              </li>
              <li className="footer__base-list-item">
                <OutboundLink
                  to="https://www.discoveryeducation.com/help/contact-us/"
                  className="footer__base-link"
                >
                  Contact Us
                </OutboundLink>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
